<template>
  <f7-page name="receiptList">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Draw Result List</f7-nav-title>
    </f7-navbar>

    <!-- Page content-->
	<f7-block>
		<div class="list">
			<ul>
				<li v-for="rec in resultList" v-bind:key="resultRecKey(rec)">
					<div v-if="rec.draw_type == '4d'" class="item-cell margin-vertical" v-bind:style="'background-color:' + rec.company_color + ';'">
						<div class="item-row">
							<div class="item-cell text-align-center">
								<h3 class="no-margin">{{ rec.company_name }} 4D - {{ rec.draw_date }}</h3>
							</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">1st</h4></div>
							<div class="item-cell"><h4 class="no-margin">2nd</h4></div>
							<div class="item-cell"><h4 class="no-margin">3rd</h4></div>
						</div>
						<div class="item-row text-align-center result-num-row">
							<div class="item-cell">{{ rec.r1 }}</div>
							<div class="item-cell">{{ rec.r2 }}</div>
							<div class="item-cell">{{ rec.r3 }}</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">Special Prize</h4></div>
						</div>
						<div class="item-row text-align-center result-num-row" >
							<div class="item-cell">{{ rec.r4 }}</div>
							<div class="item-cell">{{ rec.r5 }}</div>
							<div class="item-cell">{{ rec.r6 }}</div>
							<div class="item-cell">{{ rec.r7 }}</div>
							<div class="item-cell">{{ rec.r8 }}</div>
						</div>
						<div class="item-row text-align-center result-num-row">
							<div class="item-cell">{{ rec.r9 }}</div>
							<div class="item-cell">{{ rec.r10 }}</div>
							<div class="item-cell">{{ rec.r11 }}</div>
							<div class="item-cell">{{ rec.r12 }}</div>
							<div class="item-cell">{{ rec.r13 }}</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">Consolation Prize</h4></div>
						</div>
						<div class="item-row text-align-center result-num-row" >
							<div class="item-cell">{{ rec.r14 }}</div>
							<div class="item-cell">{{ rec.r15 }}</div>
							<div class="item-cell">{{ rec.r16 }}</div>
							<div class="item-cell">{{ rec.r17 }}</div>
							<div class="item-cell">{{ rec.r18 }}</div>
						</div>
						<div class="item-row text-align-center result-num-row">
							<div class="item-cell">{{ rec.r19 }}</div>
							<div class="item-cell">{{ rec.r20 }}</div>
							<div class="item-cell">{{ rec.r21 }}</div>
							<div class="item-cell">{{ rec.r22 }}</div>
							<div class="item-cell">{{ rec.r23 }}</div>
						</div>
					</div>

					<div v-if="rec.draw_type == '5d'" class="item-cell margin-vertical" v-bind:style="'background-color:' + rec.company_color + ';'">
						<div class="item-row">
							<div class="item-cell text-align-center">
								<h3 class="no-margin">{{ rec.company_name }} 5D - {{ rec.draw_date }}</h3>
							</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">1st</h4></div>
							<div class="item-cell result-num-row">{{ rec.r5d1 }}</div>
							<div class="item-cell"><h4 class="no-margin">4th</h4></div>
							<div class="item-cell result-num-row">{{ rec.r5d4 }}</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">2nd</h4></div>
							<div class="item-cell result-num-row">{{ rec.r5d2 }}</div>
							<div class="item-cell"><h4 class="no-margin">5th</h4></div>
							<div class="item-cell result-num-row">{{ rec.r5d5 }}</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">3rd</h4></div>
							<div class="item-cell result-num-row">{{ rec.r5d3 }}</div>
							<div class="item-cell"><h4 class="no-margin">6th</h4></div>
							<div class="item-cell result-num-row">{{ rec.r5d6 }}</div>
						</div>
					</div>

					<div v-if="rec.draw_type == '6d'" class="item-cell margin-vertical" v-bind:style="'background-color:' + rec.company_color + ';'">
						<div class="item-row">
							<div class="item-cell text-align-center">
								<h3 class="no-margin">{{ rec.company_name }} 6D - {{ rec.draw_date }}</h3>
							</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">1st</h4></div>
							<div class="item-cell result-num-row">{{ rec.r6d1 }}</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">2nd</h4></div>
							<div class="item-cell result-num-row">{{ rec.r6d21 }} or {{ rec.r6d22 }}</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">3rd</h4></div>
							<div class="item-cell result-num-row">{{ rec.r6d31 }} or {{ rec.r6d32 }}</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">4th</h4></div>
							<div class="item-cell result-num-row">{{ rec.r6d41 }} or {{ rec.r6d42 }}</div>
						</div>
						<div class="item-row text-align-center">
							<div class="item-cell"><h4 class="no-margin">5th</h4></div>
							<div class="item-cell result-num-row">{{ rec.r6d51 }} or {{ rec.r6d52 }}</div>
						</div>
					</div>

				</li>
			</ul>
		</div>
		
	</f7-block>

  </f7-page>
</template>
<style scoped>
	.result-num-row {
		background-color: white;
		color: black;
	}
	.result-title-black {
		color: black;
	}
</style>
<script>
export default {
  data() {
    return {
		resultList: [],
	};
  },
  props: {
	  params: Object
  },
  mounted() {
	if(!$f7App.deriveJwtValue()){
		$f7App.storeJwtValue(this.$f7route.query.jwt);
	}
    var vmThis = this;
    this.$f7.request.setup({
      headers: {
        Authorization: "Bearer " + $f7App.deriveJwtValue(),
      },
	});

	this.loadResultList();
  },
  watch: {
	  
  },
  methods: {
	 resultRecKey(rec){
		 var mixKey = rec.draw_date + rec.company.toString() + rec.draw_type;

		 return mixKey;
	 },
	 loadResultList() {
		var vmThis = this;
		this.$f7.request.json(API_BASE_URL + "/v2/draw-result", this.params, function (rspBody) {
			var resultList = rspBody.data;
			vmThis.resultList = [];
			for(var i=0; i < resultList.length; i++){
				vmThis.resultList.push(resultList[i]);
			}
		});
	 },
  },
};
</script>