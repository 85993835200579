<template>
  <f7-app :params="f7params" theme-dark>
    <!-- Your main view, should have "view-main" class -->
    <f7-view id="mainView" :init="false" main class="safe-areas main-view" url="/"></f7-view>

    <f7-panel right>
      <f7-view>
        <f7-page>
          <f7-block-title class="text-color-primary">Menu</f7-block-title>

          <f7-list>
			<f7-list-item link="/menu" view=".main-view" panel-close title="MAIN MENU" after="">
              <f7-icon slot="media" material="dashboard"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/" view=".main-view" panel-close title="Play 4D" after="">
              <f7-icon slot="media" material="input"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/play/3d" view=".main-view" panel-close title="Play 3D" after="">
              <f7-icon slot="media" material="input"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/play/5d" view=".main-view" panel-close title="Play 5D" after="">
              <f7-icon slot="media" material="input"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/play/6d" view=".main-view" panel-close title="Play 6D" after="">
              <f7-icon slot="media" material="input"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/history" view=".main-view" panel-close title="Bet Receipt" after="">
              <f7-icon slot="media" material="history"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/bet-detail-list" view=".main-view" panel-close title="Bet Detail" after="">
              <f7-icon slot="media" material="list"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/result" view=".main-view" panel-close title="Draw Result" after="">
              <f7-icon slot="media" material="assignment"></f7-icon>
            </f7-list-item>
			<f7-list-item v-if="supportTopup" @click="openTopup" view=".main-view" panel-close title="Topup" after="">
              <f7-icon slot="media" material="attach_money"></f7-icon>
            </f7-list-item>
			<f7-list-item v-if="supportTopup" @click="openWithdraw" view=".main-view" panel-close title="Withdraw" after="">
              <f7-icon slot="media" material="local_atm"></f7-icon>
            </f7-list-item>
			<f7-list-item @click="doLogout" view=".main-view" panel-close title="Logout" after="">
              <f7-icon slot="media" f7="escape"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-panel>

  </f7-app>
</template>
<script>
import routes from "../js/routes.js";

export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        name: "PlayF7", // App name
		theme: "auto", // Automatic theme detection

        // App routes
        routes: routes,
	  },
	  jwtValue: '',
	  userId: null,
	  supportTopup: (typeof APP_SUPPORT_TOPUP == 'undefined') ? false : APP_SUPPORT_TOPUP
    };
  },

  mounted() {
    this.$f7ready((f7) => {
      // Call F7 APIs here
      // console.log('app.vue');
	  //console.log(this.deriveJwtValue);
	  window.$f7App = this;
	  f7.views.create('#mainView', {
		  main: true,
		  pushState: true,
		  pushStateSeparator: '',
		  pushStateRoot: APP_SUB_DIRECTORY
	  });
    });
  },

  methods: {
	deriveJwtValue: function() {
		if(this.jwtValue){
			return this.jwtValue;
		}
		if(typeof(Storage) !== "undefined"){
			return sessionStorage.getItem("jwtValue");
		}

		return '';
	},
	storeJwtValue: function(jwt) {
		this.jwtValue = jwt;
		if(typeof(Storage) !== "undefined"){
			sessionStorage.setItem("jwtValue", jwt);
		}
	},
	destroyJwtValue: function() {
		this.jwtValue = '';
		if(typeof(Storage) !== "undefined"){
			sessionStorage.removeItem("jwtValue");
		}
	},
	openTopup: function() {
		if(typeof APP_SUPPORT_TOPUP == 'undefined' || !APP_SUPPORT_TOPUP){
			 return;
		}
		window.open('/topup.php?uid=' + this.$f7.userId, '_blank');
	},
	openWithdraw: function() {
		if(typeof APP_SUPPORT_TOPUP == 'undefined' || !APP_SUPPORT_TOPUP){
			 return;
		}
		window.open('/withdraw.php?uid=' + this.$f7.userId, '_blank');
	},
	doLogout: function() {
		$f7App.destroyJwtValue();
		this.$f7.panel.close();
		this.$f7.views.main.router.navigate('/login', {
			history: false,
			pushState: false
		});
	}
  }
};
</script>