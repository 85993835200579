<template>
  <f7-page name="result">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Draw Result</f7-nav-title>
    </f7-navbar>

    <!-- Page content-->
    <f7-block-title class>Filtering</f7-block-title>
    <div class="list no-hairlines-md">
      <ul>
        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input
					:value="params.drawDate"
    				@change="params.drawDate = $event.target.value"
                  	type="text"
                  	placeholder="Draw Date"
                  	readonly="readonly"
                  	id="draw-date"
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <f7-block>
      <f7-row>
        <f7-col>
          <f7-button fill @click="sendRequest">Submit</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

  </f7-page>
</template>
<style scoped>
</style>
<script>
export default {
  data() {
    return {
		params: {
			drawDate: '',
		}
	};
  },
  mounted() {

	if(!$f7App.deriveJwtValue()){
		$f7App.storeJwtValue(this.$f7route.query.jwt);
	}
    var vmThis = this;

    this.$f7.request.setup({
      headers: {
        Authorization: "Bearer " + $f7App.deriveJwtValue(),
      },
    });

    var calendarDefault = this.$f7.calendar.create({
      inputEl: "#draw-date",
      dateFormat: "yyyy-mm-dd",
    });
  },
  watch: {
	  
  },
  methods: {
	  sendRequest() {
		console.log(this.params);
		var params = {
			draw_date_from: this.params.drawDate,
			draw_date_to: this.params.drawDate
		};

		var vmThis = this;
		vmThis.$f7router.navigate('/result-list', {
				props: {
					params: params
				}
		});
	  }
  },
};
</script>