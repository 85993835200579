<template>
  <f7-page name="betDetailList">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Bet Details</f7-nav-title>
    </f7-navbar>

    <!-- Page content-->
	<div class="list no-hairlines-md">
		<ul>
			<li>
				<div class="item-content item-input">
					<div class="item-inner">
						<div class="item-input-wrap">
							<input
								:value="params.draw_date"
								@change="drawDateSelected($event.target.value)"
								type="text"
								placeholder="Draw Date"
								readonly="readonly"
								id="draw-date"
							/>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
	<f7-block>
		<f7-row class="align-items-stretch" no-gap v-for="betDetail in betDetailList" v-bind:key="betDetail.bet_detail_id">
			<f7-col>
				<h3>{{ betDetail.draw_date }} - {{ betDetail.company_name }}</h3>
				<h4 class="no-margin">{{ betDetail.bet_number }} ${{betDetail.amount}} {{ betDetail.bet_type}}</h4>
				<h4 class="no-margin">{{ betDetail.status }}</h4>
				<h4 class="no-margin" v-if="betDetail.win_status == 'won'">Win: ${{ betDetail.win_amount }} ({{ betDetail.prize_names }})</h4>
			</f7-col>
		</f7-row>

		<f7-row>
			<f7-col v-if="hasPrevPage()">
				<f7-button outline small @click="prevPage()">&lt;Prev</f7-button>
			</f7-col>
			<f7-col v-if="hasNextPage()">
				<f7-button outline small @click="nextPage()">Next&gt;</f7-button>		
			</f7-col>
		</f7-row>
	</f7-block>

  </f7-page>
</template>
<style scoped>
	div[class*="col"] {
		background: #eee;
		color: #000;
		border: 1px solid #ccc;
		padding: 5px;
		margin-bottom: 16px;
		font-size: 12px;
	}
</style>
<script>
export default {
  data() {
    return {
		params: {
			draw_date: '',
			page: 1
		},
		betDetailList: [],
		lastPage: 1,

		calDrawDate: null
	};
  },
  
  mounted() {
	if(!$f7App.deriveJwtValue()){
		$f7App.storeJwtValue(this.$f7route.query.jwt);
	}
    var vmThis = this;
    this.$f7.request.setup({
      headers: {
        Authorization: "Bearer " + $f7App.deriveJwtValue(),
      },
	});

	this.calDrawDate = this.$f7.calendar.create({
      inputEl: "#draw-date",
	  dateFormat: "yyyy-mm-dd",
	  closeOnSelect: true
    });

	this.loadBetDetail();
  },
  watch: {
	  
  },
  methods: {
	 drawDateSelected(drawDate) {
		 this.params.draw_date = drawDate;
		 this.params.page = 1;

		 this.loadBetDetail();
	 },
	 nextPage() {
		 this.params.page++;
		 this.loadBetDetail();
	 },
	 prevPage() {
		 this.params.page--;
		 this.loadBetDetail();
	 },
	 loadBetDetail() {
		var vmThis = this;
	
		this.$f7.request.json(API_BASE_URL + "/v1/bet-detail", this.params, function (rspBody) {
			var betDetailList = rspBody.data.records;
			vmThis.lastPage = rspBody.data.last_page;

			vmThis.betDetailList = [];
			for(var i=0; i < betDetailList.length; i++){
				vmThis.betDetailList.push(betDetailList[i]);
			}
		});
	 },
	 hasStrike(winStatus) {
		 return winStatus == 'won';
	 },
	 hasNextPage() {
		 return this.params.page < this.lastPage;
	 },
	 hasPrevPage() {
		 return this.params.page > 1;
	 },

  },
};
</script>