<template>
  <f7-page name="receiptList">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Receipts List</f7-nav-title>
    </f7-navbar>

    <!-- Page content-->
	<f7-block>
		<f7-row class="align-items-stretch" no-gap v-for="receipt in receiptList" v-bind:key="receipt.raw_input_id">
			<f7-col width="40" @click="showPopupReceipt(receipt.raw_input_id_formatted, receipt.status, receipt.response_text)">
				<h4>#{{ receipt.raw_input_id_formatted }}</h4>
			</f7-col>
			<f7-col width="60">
				<f7-row>
					<f7-col v-if="hasStrike(receipt.win_status)">
						<f7-button color="green" outline small @click="viewStrikeList(receipt.raw_input_id_formatted, params.draw_date_from)">Strike</f7-button>
					</f7-col>
					<f7-col v-if="isCancellable(receipt.status)">
						<f7-button color="red" outline small @click="cancelBet(receipt.raw_input_id_formatted)">Cancel</f7-button>		
					</f7-col>
				</f7-row>
			</f7-col>
		</f7-row>

		<f7-row>
			<f7-col v-if="hasPrevPage()">
				<f7-button outline small @click="prevPage()">&lt;Prev</f7-button>
			</f7-col>
			<f7-col v-if="hasNextPage()">
				<f7-button outline small @click="nextPage()">Next&gt;</f7-button>		
			</f7-col>
		</f7-row>
	</f7-block>

	<f7-popup class="demo-popup" id="popupRsp" >
      <f7-page>
        <f7-navbar title="Response">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <div class="monospace-font nl2br text-align-left border-color-lightblue">{{ popupReceiptText }}</div>
        </f7-block>
		<f7-block>
          <f7-row v-if="popupStatus == 'Active'">
			<f7-col>
				<f7-button color="red" fill @click="cancelBet(popupConfNo)">Cancel Bet</f7-button>
			</f7-col>
          </f7-row>
        </f7-block>

      </f7-page>
    </f7-popup>

  </f7-page>
</template>
<style scoped>
	div[class*="col"] {
		background: #eee;
		text-align: center;
		color: #000;
		border: 1px solid #ccc;
		padding: 5px;
		margin-bottom: 16px;
		font-size: 12px;
	}
</style>
<script>
export default {
  data() {
    return {
		receiptList: [],
		lastPage: 1,

		popupConfNo: '',
		popupStatus: '',
		popupReceiptText: ''
	};
  },
  props: {
	  params: Object
  },
  mounted() {
	if(!$f7App.deriveJwtValue()){
		$f7App.storeJwtValue(this.$f7route.query.jwt);
	}
    var vmThis = this;
    this.$f7.request.setup({
      headers: {
        Authorization: "Bearer " + $f7App.deriveJwtValue(),
      },
	});

	this.loadReceipt();
  },
  watch: {
	  
  },
  methods: {
	 viewStrikeList(rawInputId, drawDate) {
		 this.$f7router.navigate('/strike-list', {
				props: {
					params: {
						draw_date_from: drawDate,
						draw_date_to: drawDate,
						raw_input_id: rawInputId, 
						page: 1
					}
				}
		});
	 },
	 showPopupReceipt(rawInputId, activeStatus, receiptText) {
		 this.popupConfNo = rawInputId;
		 this.popupStatus = activeStatus;
		 this.popupReceiptText = receiptText;

		 this.$f7.popup.open('#popupRsp', true);
	 },
	 nextPage() {
		 this.params.page++;
		 this.loadReceipt();
	 },
	 prevPage() {
		 this.params.page--;
		 this.loadReceipt();
	 },
	 loadReceipt() {
		var vmThis = this;
		this.$f7.request.json(API_BASE_URL + "/v1/raw-input/receipt", this.params, function (rspBody) {
			var rcpList = rspBody.data.receipt_list;
			vmThis.lastPage = rspBody.data.last_page;

			vmThis.receiptList = [];
			for(var i=0; i < rcpList.length; i++){
				vmThis.receiptList.push(rcpList[i]);
			}
		});
	 },
	 hasStrike(winStatus) {
		 return winStatus == 'won';
	 },
	 hasNextPage() {
		 return this.params.page < this.lastPage;
	 },
	 hasPrevPage() {
		 return this.params.page > 1;
	 },

	 isCancellable(receiptStatus) {
		 return receiptStatus == 'Active';
	 },

	 cancelBet(confNo) {
		var vmThis = this;

		this.$f7.dialog.confirm('Confirm to cancel #' + confNo, function () {
			var params = {orderid: confNo};
			vmThis.$f7.request.post(
				API_BASE_URL + "/v2/bet/cancel",
				params,
				function (rspBody) {	
					var toast = vmThis.$f7.toast.create({
						text: rspBody.receipt,
						position: 'center',
						closeTimeout: 3000,
					});
					toast.open();

					// Refresh the receipt list
					vmThis.loadReceipt();

					vmThis.$f7.popup.close('#popupRsp', true);
				},
				function (xhr, status) {
					console.log("Cancel bet failed");
					console.log(xhr.responseText);
					console.log(status);

					var rsp = JSON.parse(xhr.responseText);
					var msg = 'Cancel bet failed';
					if(status >= 400 && status < 500){
						msg = rsp.data.reason;
					} else if (status >= 500 && status < 600){
						msg = rsp.message;
					}

					var toast = vmThis.$f7.toast.create({
						text: msg,
						position: 'center',
						closeTimeout: 5000,
					});
					toast.open();
				},
				"json"
			);
		});
	 }
  },
};
</script>