
import MenuPage from '../pages/menu.vue';
import PlayPage from '../pages/play.vue';
import ReceiptDetailPage from '../pages/ReceiptDetail.vue';
import HistoryPage from '../pages/history.vue';
import ReceiptListPage from '../pages/ReceiptList.vue';
import BetDetailListPage from '../pages/BetDetailList.vue';
import StrikeListPage from '../pages/StrikeList.vue';
import ResultPage from '../pages/Result.vue';
import ResultListPage from '../pages/ResultList.vue';
import LoginPage from '../pages/Login.vue';

var appCheckLogin = function (routeTo, routeFrom, resolve, reject) {
	//console.log(routeTo);
	//console.log(this.app);
	//console.log($f7App);
	console.log($f7App.deriveJwtValue());
	if(!$f7App.deriveJwtValue() && !routeTo.query.jwt){
		reject();
		this.navigate('/login', {
			history: false,
			pushState: false
		});
	} else {
		resolve();
	}
  }

var routes = [
  {
	path: '/menu',
	component: MenuPage,
	beforeEnter: appCheckLogin
  },
  {
    path: '/',
	component: PlayPage,
	options: {
		props: {
		  gameType: '4d',
		},
	},
	beforeEnter: appCheckLogin
  },
  {
    path: '/play/3d',
	component: PlayPage,
	options: {
		props: {
		  gameType: '3d',
		},
	},
	beforeEnter: appCheckLogin
  },
  {
    path: '/play/5d',
	component: PlayPage,
	options: {
		props: {
		  gameType: '5d',
		},
	},
	beforeEnter: appCheckLogin
  },
  {
    path: '/play/6d',
	component: PlayPage,
	options: {
		props: {
		  gameType: '6d',
		},
	},
	beforeEnter: appCheckLogin
  },
  {
	path: '/receipt',
	component: ReceiptDetailPage,
	options: {
	  props: {
		rspReceipt: '',
		confNo: '',
		betStatus: 'failed'
	  },
	},
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/history',
	component: HistoryPage,
	beforeEnter: appCheckLogin
  },
  {
	path: '/receipt-list',
	component: ReceiptListPage,
	beforeEnter: appCheckLogin,
	options: {
	  props: {
		params: {}
	  },
	},
  },
  {
	path: '/bet-detail-list',
	component: BetDetailListPage,
	beforeEnter: appCheckLogin
  },
  {
	path: '/strike-list',
	component: StrikeListPage,
	beforeEnter: appCheckLogin,
	options: {
	  props: {
		params: {}
	  },
	},
  },
  {
    path: '/result',
	component: ResultPage,
	beforeEnter: appCheckLogin
  },
  {
	path: '/result-list',
	component: ResultListPage,
	beforeEnter: appCheckLogin,
	options: {
	  props: {
		params: {}
	  },
	},
  },
];

export default routes;
