<template>
  <f7-page name="menu">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left v-if="logoSrc.length > 0">
        <img class="nav-logo" :src="logoSrc" />
      </f7-nav-left>
      <f7-nav-title>
        <span class="text-color-primary">{{ userDisplayName }}</span> / ${{
          creditBalance | formatDollar
        }}
      </f7-nav-title>
      <f7-nav-right>
        <f7-link icon-material="menu" panel-open="right"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!-- Page content-->
    <f7-block>
      <f7-row>
		<f7-col @click="navToPath('/')"><h2>Play 4D</h2></f7-col>
		<f7-col @click="navToPath('/play/3d')"><h2>Play 3D</h2></f7-col>
		<f7-col @click="navToPath('/play/5d')"><h2>Play 5D</h2></f7-col>
  	  </f7-row>
	  <f7-row>
		<f7-col @click="navToPath('/play/6d')"><h2>Play 6D</h2></f7-col>
		<f7-col @click="navToPath('/history')"><h2>Bet Receipt</h2></f7-col>
		<f7-col @click="navToPath('/bet-detail-list')"><h2>Bet Detail</h2></f7-col>
  	  </f7-row>
	<f7-row>
		<f7-col width="33" @click="navToPath('/result')"><h2>Draw Result</h2></f7-col>
		<f7-col width="33" @click="doLogout()"><h2>Logout</h2></f7-col>
		<f7-col width="33" class="hide"></f7-col>
  	  </f7-row>


    </f7-block>
    
  </f7-page>
</template>
<style scoped>
div[class*="col"] {
  background: var(--f7-color-blue-tint);
  text-align: center;
  color: #000;
  border: 1px solid #ddd;
  padding: 25px 5px;
  margin-bottom: 16px;
  font-size: 12px;
}
h2 {
	font-size: 115%;
}
.hide {
	visibility: hidden;
}
</style>
<script>
export default {
  data() {
    return {
	  logoSrc: (typeof LOGO_SRC == 'undefined') ? '' : LOGO_SRC,
      userId: "",
      userDisplayName: "",
      creditBalance: 3000,
    };
  },

  mounted() {
    if (!$f7App.deriveJwtValue()) {
      // Attempt to extract from URL query
      if (this.$f7route.query.jwt) {
        $f7App.storeJwtValue(this.$f7route.query.jwt);
      }
    }

    this.$f7.request.setup({
      headers: {
        Authorization: "Bearer " + $f7App.deriveJwtValue(),
      },
    });

    var vmThis = this;
    this.$f7.request.json(
      API_BASE_URL + "/v1/downline?include_self=1",
      function (rspBody) {
        //console.log(rspBody);
        var userList = rspBody.data;
        if (userList.length > 0) {
          vmThis.userId = userList[0].user_id;
        }
      }
    );
  },
  watch: {
    userId: function (newVal, oldVal) {
      if (!newVal || newVal.toString().length == 0) {
        return;
      }

      var vmThis = this;
      this.$f7.request.json(
        API_BASE_URL + "/v1/user/" + newVal + "/basic",
        function (rspBody) {
          //console.log(rspBody);
          var fields = rspBody.data;
          vmThis.creditBalance = fields.credit_balance;
          vmThis.userDisplayName = fields.phone_username;
        }
      );
    },
  },
  filters: {
	  formatDollar: function(val){
		  return parseFloat(val).toLocaleString();
	  }
  },
  methods: {
	  navToPath: function(path) {
		  this.$f7router.navigate(path);
	  },
	  doLogout: function() {
		  $f7App.doLogout();
	  }
  },
};
</script>