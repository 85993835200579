<template>
  <f7-page name="play">
    <!-- Top Navbar -->
    <f7-navbar>
		<f7-nav-left v-if="logoSrc.length > 0">
    		<img class="nav-logo" :src="logoSrc" />
  		</f7-nav-left>
		<f7-nav-title>
			<span class="text-color-primary">{{ userDisplayName }}</span> / ${{creditBalance | formatDollar}}
		</f7-nav-title>
		<f7-nav-right>
			<f7-link icon-material="menu" panel-open="right"></f7-link>
		</f7-nav-right>
    </f7-navbar>

    <!-- Extended FAB Center Bottom (Red) -->
    <f7-fab position="center-bottom" slot="fixed" text="Submit" color="blue" @click="submitClicked"></f7-fab>

    <!-- Page content-->
    <f7-block strong>
      <f7-row>
        <f7-col v-for="company in companyList" v-bind:key="company.company_id">
          <f7-checkbox
            v-bind:value="company.company_id"
            :checked="selectedCompanies.indexOf(company.company_id) >= 0"
            @change="checkCompany"
          >
            <img class="company_logo" v-bind:src="company.logo_url" />
            <span>{{company.company_code}}</span>
          </f7-checkbox>
        </f7-col>
      </f7-row>
      <hr />
      <f7-row>
        <f7-col v-for="drawSess in drawSessList" v-bind:key="drawSess.draw_date">
          <f7-checkbox
            v-bind:value="drawSess.draw_date"
            :checked="selectedDrawDate.indexOf(drawSess.draw_date) >= 0"
            @change="checkDrawDate"
          >
            <span>{{drawSess.display_date}}</span><br />
			<span>({{drawSess.draw_weekday}})</span>
          </f7-checkbox>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block strong>
      <f7-row class="margin-top margin-bottom">
        <f7-col width="30" v-if="betTypeShowCol.numType">Num type</f7-col>
        <f7-col :width="inputColWidth.number">{{ labels.number }}</f7-col>
        <f7-col :width="inputColWidth.big" v-if="betTypeShowCol.big">
          <f7-checkbox @change="checkBig">{{ labels.big }}</f7-checkbox>
        </f7-col>
        <f7-col :width="inputColWidth.small" v-if="betTypeShowCol.small">
          <f7-checkbox @change="checkSmall">{{ labels.small }}</f7-checkbox>
        </f7-col>
        <f7-col :width="inputColWidth.sa" v-if="betTypeShowCol.sa">
          <f7-checkbox @change="check4a">{{ labels.sa }}</f7-checkbox>
        </f7-col>
      </f7-row>

      <f7-row v-for="rec in inputList" v-bind:key="rec.id" class="margin-top margin-bottom">
        <f7-col width="30" v-if="betTypeShowCol.numType">
          <f7-input type="select" :value="rec.numType" @input="rec.numType = $event.target.value"
		  :outline="true" inputStyle="padding: 5px;">
            <option value="0">SINGLE</option>
            <option value="1">BOX</option>
            <option value="2">IBOX</option>
            <option value="3">REVERSE</option>
          </f7-input>
        </f7-col>
        <f7-col :width="inputColWidth.number">
          <f7-input
            type="number" inputmode="numeric" :outline="true" inputStyle="padding: 5px;"
            placeholder="number"
            :value="rec.betNumber"
            @input="rec.betNumber = $event.target.value"
          />
        </f7-col>
        <f7-col :width="inputColWidth.big" v-if="betTypeShowCol.big">
          <f7-input
            type="number" inputmode="decimal" :outline="true" inputStyle="padding: 5px;"
            placeholder="amount"
            :value="rec.amtBig"
            @input="rec.amtBig = $event.target.value"
          />
        </f7-col>
        <f7-col :width="inputColWidth.small" v-if="betTypeShowCol.small">
          <f7-input
            type="number" inputmode="decimal" :outline="true" inputStyle="padding: 5px;"
            placeholder="amount"
            :value="rec.amtSmall"
            @input="rec.amtSmall = $event.target.value"
          />
        </f7-col>
        <f7-col :width="inputColWidth.sa" v-if="betTypeShowCol.sa">
          <f7-input
            type="number" inputmode="decimal" :outline="true" inputStyle="padding: 5px;"
            placeholder="amount"
            :value="rec.amt4a"
            @input="rec.amt4a = $event.target.value"
          />
        </f7-col>
      </f7-row>
    </f7-block>

  </f7-page>
</template>
<style scoped>
img.company_logo {
  max-width: 25px;
}
img.nav-logo {
	max-width: 50px;
	height: auto;
}
</style>
<script>
export default {
  data() {
    return {
      userId: "",
      userDisplayName: "",
      creditBalance: 3000,
      companyList: [],
      drawSessList: [],
      inputList: [],

      selectedCompanies: [],
	  selectedDrawDate: [],
	  
	  rspReceipt: 'Red fox jump 123',
	  confNo: '',

	  inputColWidth: {
		  number: 25,
		  big: 15,
		  small: 15,
		  sa: 15
	  },
	  labels: {
		  number: '4D Num',
		  big: 'Big',
		  small: 'Small',
		  sa: '4A'
	  },
	  betTypeShowCol: {
		  numType: true,
		  big: true,
		  small: true,
		  sa: true
	  },

	  logoSrc: (typeof LOGO_SRC == 'undefined') ? '' : LOGO_SRC
    };
  },

  props: {
	  gameType: String
  },

  filters: {
	  formatDollar: function(val){
		  return parseFloat(val).toLocaleString();
	  }
  },

  mounted() {
    //   console.log('num711');
	//   console.log(this.$f7route.query);
	//	console.log(this.$f7);

	console.log(this.gameType);

	switch(this.gameType){
		case '4d':
			
			this.labels.number = '4D NUM';
			this.labels.big = 'Big';
			this.labels.small = 'Small';
			this.labels.sa = '4A';

			this.betTypeShowCol.numType = true;
			this.betTypeShowCol.big = true;
			this.betTypeShowCol.small = true;
			this.betTypeShowCol.sa = true;

			this.inputColWidth.number = 25;
			this.inputColWidth.big = 15;
			this.inputColWidth.small = 15;
			this.inputColWidth.sa = 15;
		break;
		case '3d':
			
			this.labels.number = '3D NUM';
			this.labels.big = 'ABC';
			this.labels.small = '';
			this.labels.sa = '3A';

			this.betTypeShowCol.numType = true;
			this.betTypeShowCol.big = true;
			this.betTypeShowCol.small = false;
			this.betTypeShowCol.sa = true;

			this.inputColWidth.number = 25;
			this.inputColWidth.big = 20;
			this.inputColWidth.small = 0;
			this.inputColWidth.sa = 20;
		break;
		case '5d':
		case '6d':
			
			this.labels.number = this.gameType == '5d' ? '5D NUM' : '6D NUM';
			this.labels.big = 'Big';
			this.labels.small = '';
			this.labels.sa = '';

			this.betTypeShowCol.numType = false;
			this.betTypeShowCol.big = true;
			this.betTypeShowCol.small = false;
			this.betTypeShowCol.sa = false;

			this.inputColWidth.number = 50;
			this.inputColWidth.big = 50;
			this.inputColWidth.small = 0;
			this.inputColWidth.sa = 0;
		break;
	}

	var vmThis = this;
	if(!$f7App.deriveJwtValue()){
		// Attempt to extract from URL query
		if(this.$f7route.query.jwt){
			$f7App.storeJwtValue(this.$f7route.query.jwt);
		}
	}
    
    this.$f7.request.setup({
      headers: {
        Authorization: "Bearer " + $f7App.deriveJwtValue(),
      },
    });

    this.$f7.request.json(
      API_BASE_URL + "/v1/downline?include_self=1",
      function (rspBody) {
        //console.log(rspBody);
        var userList = rspBody.data;
        if (userList.length > 0) {
          vmThis.userId = userList[0].user_id;
        }
      }
    );

    this.$f7.request.json(API_BASE_URL + "/v1/company", function (rspBody) {
      //console.log(rspBody);
      var companyList = rspBody.data;
      vmThis.companyList = [];
      for (var i = 0; i < companyList.length; i++) {
        vmThis.companyList.push(companyList[i]);
      }
    });

    this.$f7.request.json(
      API_BASE_URL + "/v1/draw-session/upcoming?max_record=7",
      function (rspBody) {
        var drawSessionList = rspBody.data;
        vmThis.drawSessList = [];
        for (var i = 0; i < drawSessionList.length; i++) {
          vmThis.drawSessList.push(drawSessionList[i]);
        }
      }
    );

    for (var i = 0; i < 20; i++) {
      var recInput = {
        id: i + 1,
        numType: 0,
        betNumber: "",
        amtBig: "",
        amtSmall: "",
        amt4a: "",
      };

      this.inputList.push(recInput);
	}
	
	//this.$f7.popup.open('#popupRsp', true);
  },
  watch: {
	  confNo: function(newVal, oldVal){
		  console.log('confNo changed: ' + newVal);

		  if(!newVal || newVal.toString().length == 0){
			  this.$$('#btnCancel').addClass('display-none');
		  } else {
			  this.$$('#btnCancel').removeClass('display-none');
		  }
	  },

    userId: function (newVal, oldVal) {
      if (!newVal || newVal.toString().length == 0) {
        return;
      }

      var vmThis = this;
      this.$f7.request.json(
        API_BASE_URL + "/v1/user/" + newVal + "/basic",
        function (rspBody) {
          //console.log(rspBody);
          var fields = rspBody.data;
          vmThis.creditBalance = fields.credit_balance;
          vmThis.userDisplayName = fields.phone_username;
        }
      );
    },
  },
  methods: {
	refreshPage() {
		this.$f7router.refreshPage();
	},
    submitClicked(event) {
      console.log("submit clicked");
	  var self = this;

      var params = {
        target_user_id: this.userId,
        recipient_phone_no: "",
        bet_number: [],
        amt_b: [],
        amt_s: [],
        amt_a: [],
        amt_ta: [],
        amt_abc: [],
        amt_5d: [],
        amt_6d: [],
        bet_option: [],
      };

      for (var i = 0; i < this.inputList.length; i++) {
		var betNum = this.inputList[i].betNumber;
        if (betNum.length > 0) {
        	params.bet_number.push(this.inputList[i].betNumber);
			params.bet_option.push(this.inputList[i].numType);
			params["draw_days[" + i + "]"] = this.selectedDrawDate;
        	params["companies[" + i + "]"] = this.selectedCompanies;

			switch(betNum.length){
				case 4:
					var amt = parseFloat(this.inputList[i].amtBig);
					params.amt_b.push(isNaN(amt) ? 0 : amt);
					amt = parseFloat(this.inputList[i].amtSmall);
					params.amt_s.push(isNaN(amt) ? 0 : amt);
					amt = parseFloat(this.inputList[i].amt4a);
					params.amt_a.push(isNaN(amt) ? 0 : amt);

					params.amt_ta.push(0);
					params.amt_abc.push(0);
					params.amt_5d.push(0);
					params.amt_6d.push(0);
				break;
				case 3:
					var amt = parseFloat(this.inputList[i].amtBig);
					params.amt_abc.push(isNaN(amt) ? 0 : amt);
					
					amt = parseFloat(this.inputList[i].amt4a);
					params.amt_ta.push(isNaN(amt) ? 0 : amt);

					params.amt_b.push(0);
					params.amt_s.push(0);
					params.amt_a.push(0);
					params.amt_5d.push(0);
					params.amt_6d.push(0);
				break;
				case 5:
					var amt = parseFloat(this.inputList[i].amtBig);
					params.amt_5d.push(isNaN(amt) ? 0 : amt);

					params.amt_b.push(0);
					params.amt_s.push(0);
					params.amt_a.push(0);
					params.amt_ta.push(0);
					params.amt_abc.push(0);
					
					params.amt_6d.push(0);
				break;
				case 6:
					var amt = parseFloat(this.inputList[i].amtBig);
					params.amt_6d.push(isNaN(amt) ? 0 : amt);

					params.amt_b.push(0);
					params.amt_s.push(0);
					params.amt_a.push(0);
					params.amt_ta.push(0);
					params.amt_abc.push(0);
					
					params.amt_5d.push(0);
				break;
			}
        }
      }

      console.log("params");
      console.log(params);

      this.$f7.request.post(
        API_BASE_URL + "/v1/play",
        params,
        function (rspBody) {
		  //console.log(rspBody);
		  self.confNo = rspBody.data.conf_no;
		  self.rspReceipt = rspBody.data.response;

		  console.log("Bet successful, #" + self.confNo.toString());
		  console.log(rspBody.data);

		  if(rspBody.data.bet_status !== 'active'){
			var rspHtml = rspBody.data.response.replace(/(?:\r\n|\r|\n)/g, '<br />');
			self.$f7.dialog.alert(rspHtml, 'Bet Error');
			return;
		  }

		  self.$f7router.navigate('/receipt', {
			  props: {
	  			confNo: rspBody.data.conf_no.toString(),
				betStatus: rspBody.data.bet_status,
				rspReceipt: rspBody.data.response
			   }
		  });
        },
        function (xhr, status) {
          console.log("Bet failed");
          console.log(xhr.responseText);
		  console.log(status);
		  
		  var rspHtml = xhr.responseText.replace(/(?:\r\n|\r|\n)/g, '<br />');
		  self.rspReceipt = xhr.responseText;
		  self.$f7.dialog.alert(rspHtml, 'Error');
        },
        "json"
      );
	},
	
    checkCompany(event) {
      const self = this;
      const value = event.target.value;
      if (event.target.checked) {
        self.selectedCompanies.push(value);
      } else {
        self.selectedCompanies.splice(self.selectedCompanies.indexOf(value), 1);
      }
    },
    checkDrawDate(event) {
      const self = this;
      const value = event.target.value;
      if (event.target.checked) {
        self.selectedDrawDate.push(value);
      } else {
        self.selectedDrawDate.splice(self.selectedDrawDate.indexOf(value), 1);
      }
    },
    checkBetAmt(event, amtName) {
      const self = this;
      const value = event.target.value;
      if (event.target.checked) {
        var firstValue = null;
        for (var i = 0; i < this.inputList.length; i++) {
          if (i == 0) {
            firstValue = this.inputList[i][amtName];
            continue;
          }
          this.inputList[i][amtName] = firstValue;
        }
      } else {
        for (var i = 0; i < this.inputList.length; i++) {
          if (i == 0) {
            continue;
          }
          this.inputList[i][amtName] = "";
        }
      }
    },
    checkBig(event) {
		this.checkBetAmt(event, 'amtBig');
    },
    checkSmall(event) {
		this.checkBetAmt(event, 'amtSmall');
	},
    check4a(event) {
		this.checkBetAmt(event, 'amt4a');
	},
  },
};
</script>