<template>
  <f7-page name="betDetailList">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-title>Login</f7-nav-title>
    </f7-navbar>

    <!-- Page content-->
	<f7-block v-if="logoSrc.length > 0">
		<div class="text-align-center padding">
			<img class="login-logo" :src="logoSrc" />
		</div>
	</f7-block>
	<div class="list no-hairlines-md">
		<ul>
			<li>
				<div class="item-content item-input">
					<div class="item-inner">
						<div class="item-input-wrap">
							<input
								:value="params.login_name"
								@input="params.login_name = $event.target.value"
								type="text"
								placeholder="Login username"
							/>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="item-content item-input">
					<div class="item-inner">
						<div class="item-input-wrap">
							<input
								:value="params.login_password"
								@input="params.login_password = $event.target.value"
								type="password"
								placeholder="Login password"
							/>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
	<f7-block>
		<f7-button fill @click="sendRequest">Submit</f7-button>
		<div v-if="supportTopup" class="text-align-center padding">
			<a class="link external" href="#" @click="openRegister">Register</a>
		</div>
    </f7-block>
  </f7-page>
</template>
<style scoped>
	div[class*="col"] {
		background: #eee;
		color: #000;
		border: 1px solid #ccc;
		padding: 5px;
		margin-bottom: 16px;
		font-size: 12px;
	}
	img.login-logo {
		max-width: 90px;
		height: auto;
	}
</style>
<script>
export default {
  data() {
    return {
		params: {
			login_name: '',
			login_password: ''
		},
		logoSrc: (typeof LOGO_SRC == 'undefined') ? '' : LOGO_SRC,
		supportTopup: (typeof APP_SUPPORT_TOPUP == 'undefined') ? false : APP_SUPPORT_TOPUP
	};
  },
  
  mounted() {
	
  },
  watch: {
	  
  },
  methods: {
	 openRegister: function() {
		 if(typeof APP_SUPPORT_TOPUP == 'undefined' || !APP_SUPPORT_TOPUP){
			 return;
		 }

		 window.open('/register.php', '_blank');
	 },
	 sendRequest() {
		var vmThis = this;
		this.$f7.request.post(API_BASE_URL + "/v1/user/jwt", this.params, 
			function (rspBody) {
				var jwtData = rspBody.data;
				$f7App.userId = jwtData.user_id;
				$f7App.storeJwtValue(jwtData.jwt);
				//console.log($f7App.deriveJwtValue());

				vmThis.$f7router.navigate('/menu', {
					//clearPreviousHistory: true,
					//reloadCurrent: true,
					//reloadAll: true,
					ignoreCache: true
				});
			},
			function (xhr, status, message) {
				console.log(message);
				console.log(xhr.response);
				
				var rspBody = JSON.parse(xhr.response);
				var displayMsg = '';
				if(status >= 400 && status < 500){
					displayMsg = rspBody.data.reason;
				}
				if(status >= 500){
					displayMsg = rspBody.data.message;
				}

				vmThis.$f7.toast.create({
					text: displayMsg,
					closeTimeout: 4500,
				}).open();
			},
			'json'
		);
	 },
  },
};
</script>