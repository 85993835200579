<template>
  <f7-page name="history">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Bet History</f7-nav-title>
    </f7-navbar>

    <!-- Page content-->
    <f7-block-title class>Filtering</f7-block-title>
    <div class="list no-hairlines-md">
      <ul>
        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input
					:value="params.drawDate"
    				@change="params.drawDate = $event.target.value"
                  	type="text"
                  	placeholder="Draw Date"
                  	readonly="readonly"
                  	id="draw-date"
                />
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input type="text" name="confNo" placeholder="Receipt No"
					:value="params.confNo"
    				@input="params.confNo = $event.target.value" />
                <span class="input-clear-button"></span>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap input-dropdown-wrap">
                <select :value="params.status"
    					@input="params.status = $event.target.value">
                  <option value>Status...</option>
                  <option value="active">Active</option>
                  <option value="failed">Failed</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="partial">Partially Active</option>
                </select>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap input-dropdown-wrap">
                <select :value="params.strikeStatus"
    					@input="params.strikeStatus = $event.target.value">
                  <option value>Strike Status...</option>
                  <option value="pending">Pending</option>
                  <option value="won">Won</option>
                  <option value="lose">Lose</option>
                </select>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <f7-block>
      <f7-row>
        <f7-col>
          <f7-button fill @click="sendRequest">Submit</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

  </f7-page>
</template>
<style scoped>
</style>
<script>
export default {
  data() {
    return {
		params: {
			drawDate: '',
			confNo: '',
			status: '',
			strikeStatus: ''
		}
	};
  },
  mounted() {

	if(!$f7App.deriveJwtValue()){
		$f7App.storeJwtValue(this.$f7route.query.jwt);
	}
    var vmThis = this;

    this.$f7.request.setup({
      headers: {
        Authorization: "Bearer " + $f7App.deriveJwtValue(),
      },
    });

    var calendarDefault = this.$f7.calendar.create({
      inputEl: "#draw-date",
      dateFormat: "yyyy-mm-dd",
    });
  },
  watch: {
	  
  },
  methods: {
	  sendRequest() {
		console.log(this.params);
		var params = {
			draw_date_from: this.params.drawDate,
			draw_date_to: this.params.drawDate,
			record_id: this.params.confNo,
			raw_input_status: this.params.status,
			win_status: this.params.strikeStatus,
			page: 1
		};

		var vmThis = this;
		vmThis.$f7router.navigate('/receipt-list', {
				props: {
					params: params
				}
		});
	  }
  },
};
</script>