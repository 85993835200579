<template>
  <f7-page name="receiptDetail">
    <!-- Top Navbar -->
    <f7-navbar>
      
	  <a href="#" slot="left" @click="navBack"> <f7-icon icon="icon-back"></f7-icon> </a>
      <f7-nav-title>Bet Response</f7-nav-title>
    </f7-navbar>

    <!-- Page content-->
    <f7-block>
      <div class="monospace-font nl2br text-align-left border-color-lightblue">{{ displayReceipt }}</div>
    </f7-block>
    <f7-block v-if="showCancel">
      <f7-row>
        <f7-col>
          <f7-button fill @click="cancelBet" id="btnCancel">Cancel Bet</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<style scoped>

</style>
<script>
export default {
  data() {
    return {
	  showCancel: false,
	  displayReceipt: '',
	  rawInputId: null
    };
  },

  props: {
	  rspReceipt: String,
	  confNo: String,
	  betStatus: String
  },

  mounted() {
	  this.showCancel = this.betStatus === 'active';
	  this.displayReceipt = this.rspReceipt;
	  this.rawInputId = parseInt(this.confNo);
  },
  watch: {
	  rawInputId: function(newVal, oldVal){
		  if(newVal === null){
			  this.showCancel = false;
		  }
	  }
  },
  methods: {
	  navBack() {
		  console.log('navBack:' + this.betStatus);
		  this.$f7router.back('/', {
			  ignoreCache: this.betStatus === 'active',
			  force: this.betStatus === 'active'
		  });
	  },
	  cancelBet() {
		var params = {orderid: this.rawInputId};
		var self = this;

		this.$f7.request.post(
			API_BASE_URL + "/v2/bet/cancel",
			params,
			function (rspBody) {	
				self.displayReceipt = rspBody.receipt;
				self.rawInputId = null;
			},
			function (xhr, status) {
				console.log("Cancel bet failed");
				console.log(xhr.responseText);
				console.log(status);
				self.rspReceipt = xhr.responseText;
			},
			"json"
		);
	},
   
  }
};
</script>