<template>
  <f7-page name="strikeList">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Strike for #{{ params.raw_input_id }}</f7-nav-title>
    </f7-navbar>

    <!-- Page content-->
	<f7-block>
		<f7-row class="align-items-stretch" no-gap v-for="strike in strikeList" v-bind:key="strike.strike_detail_id">
			<f7-col width="25" class="padding">
				{{ strike.company_name }}
			</f7-col>
			<f7-col width="25" class="padding">
				{{ strike.prize_name }}
			</f7-col>
			<f7-col width="50" class="text-align-left">
				<p>{{ strike.bet_number }}</p>
				<p>${{ strike.bet_amount_converted }} {{ strike.bet_bsa }}</p>
				<p>Won: ${{ strike.strike_amount }}</p>
				<p>Seq No: {{ strike.daily_seq_no }}</p>
				<p>Phone: {{ strike.phone_no }}</p>
			</f7-col>
		</f7-row>

		<f7-row>
			<f7-col  v-if="hasPrevPage()">
				<f7-button outline small @click="prevPage()">&lt;Prev</f7-button>
			</f7-col>
			<f7-col v-if="hasNextPage()">
				<f7-button outline small @click="nextPage()">Next&gt;</f7-button>		
			</f7-col>
		</f7-row>
	</f7-block>
  </f7-page>
</template>
<style scoped>
	div[class*="col"] {
		background: #eee;
		text-align: center;
		color: #000;
		border: 1px solid #ccc;
		padding: 5px;
		margin-bottom: 16px;
		font-size: 12px;
	}
</style>
<script>
export default {
  data() {
    return {
		strikeList: [],
		lastPage: 1,
	};
  },
  props: {
	  params: Object
  },
  mounted() {
	if(!$f7App.deriveJwtValue()){
		$f7App.storeJwtValue(this.$f7route.query.jwt);
	}
    var vmThis = this;
    this.$f7.request.setup({
      headers: {
        Authorization: "Bearer " + $f7App.deriveJwtValue(),
      },
	});

	this.loadStrike();
  },
  watch: {
	  
  },
  methods: {
	 nextPage() {
		 this.params.page++;
		 this.loadStrike();
	 },
	 prevPage() {
		 this.params.page--;
		 this.loadStrike();
	 },
	 loadStrike() {
		var vmThis = this;
		this.$f7.request.json(API_BASE_URL + "/v1/strike-report", this.params, function (rspBody) {
			var recList = rspBody.data.records;
			vmThis.lastPage = rspBody.data.last_page;

			vmThis.strikeList = [];
			for(var i=0; i < recList.length; i++){
				vmThis.strikeList.push(recList[i]);
			}
		});
	 },
	 
	 hasNextPage() {
		 return this.params.page < this.lastPage;
	 },
	 hasPrevPage() {
		 return this.params.page > 1;
	 },

  },
};
</script>